.form {
  display: flex;
  // margin-bottom: rem(50px);
  background-color: #fff8ee;
  padding: 55px;
  margin-left: 100px;
  padding-right: 20vw;
  z-index: 1;
  position: relative;
  border: solid 1px white;

  a {
    text-decoration: underline;
  }

  :focus {
    outline: none;
  }

  label {
    color: #3f3f3f;
  }

  &__row {
    position: relative;
    margin-top: 15px;
    border-bottom: 1px solid #f19a7b;
    padding-top: 20px;
  }

  &__input {
    margin-top: 5px;
    padding: 9px 12px;
    border: 0;
    background-color: transparent;
    position: relative;
    font-size: rem(16px);
    width: 100%;
    color: #ffffff;
    &--checkbox {
      margin: 0 5px 0 0;
      display: none;

      &:checked + label .fakebox {
        background-color: #4f647c56;
      }
    }
    &--textarea {
      margin-bottom: -7px;
      height: 99%;
    }

    // & ~ .form__border {
    //   position: absolute;
    //   bottom: 0;
    //   left: 0;
    //   width: 0;
    //   height: 2px;
    //   background-color: $color-main;
    //   transition: 0.3s;
    // }

    // &:focus ~ .form__border {
    //   width: 100%;
    //   transition: 0.3s;
    // }

    &:focus ~ .form__label {
      top: 5px;
      font-size: 12px;
      color: #4f647c;
      transition: 0.3s;
    }

    &--has-content ~ .form__border {
      width: 100%;
      transition: 0.4s;
    }

    &--has-content ~ .form__label {
      top: 5px;
      font-size: 12px;
      color: #ffffff;
      transition: 0.3s;
    }
  }

  &__label {
    position: absolute;
    left: 15px;
    width: 100%;
    top: 40px;
    color: #ffffff !important;
    transition: 0.3s;
    letter-spacing: 0.5px;
    display: block;
    margin-bottom: 15px;
    font-size: rem(17px);
    font-weight: 600;
  }

  &__rodo {
    font-size: rem(14px);
    width: 100%;
    margin-top: rem(30px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @include mq($until: lg) {
      margin-left: 0px !important;
    }
    label,
    input {
      cursor: pointer;
    }

    label {
      display: flex;
      align-items: center;
    }
  }

  select {
    background-color: unset;
  }

  option {
    margin: 5px;
  }

  & > div {
    margin-right: 15px;
    width: 100%;

    &:first-child {
      & > div {
        &:last-child {
          // height: calc(100% - 108px);
        }
      }
    }

    @include mq($until: xl) {
      width: 430px;

      &:first-child {
        & > div {
          &:last-child {
            // height: calc(100% - 105px);
          }
        }
      }
    }
  }

  @include mq($until: xl) {
    margin-left: 0;
    padding: 35px 160px 35px 35px;
  }

  @include mq($until: lg) {
    padding: 35px 40vw 35px 35px;
    flex-direction: column;
  }

  @include mq($until: lg) {
    padding: 30px;

    & > div {
      margin-right: 0;
      width: 100%;
    }
  }

  @include mq($until: xs) {
    padding: 15px;
  }
}

#topic {
  font-size: rem(18px);
}

.close {
  &:hover {
    cursor: pointer;
  }
}

.alert {
  h4 {
    margin-top: 15px;
    font-size: rem(20px);
  }
}

.contact-form {
  padding-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  @include mq($until: lg) {
    width: 100%;
    padding-bottom: 0px !important;
    flex-direction: column-reverse;
  }
  h2 {
    color: white;
    margin-bottom: 50px;
    text-transform: initial;
    margin-left: rem(100px);
    font-size: rem(35px);
    @include mq($until: sm) {
      text-align: center;
    }
  }
  .contact-p {
    margin-left: rem(100px);
    width: unset !important;
    bottom: unset !important;
    left: unset !important;
    z-index: unset !important;
    position: unset !important;
    box-shadow: unset !important;
    @include mq($until: xl) {
      margin-left: 0px;
    }
  }
  .contact-logo {
    width: 40%;
    float: right;
    @include mq($until: lg) {
      width: 100%;
    }
  }
  .contact-container {
    background-color: #e27650;
    width: 40%;
    padding: rem(60px) rem(20px) rem(30px) rem(30px);
    @include mq($until: lg) {
      width: 100%;
      margin-bottom: 0px;
      padding: 7vw 7vw !important;
    }
    .form {
      background-color: #e27650;
      padding-top: 0px !important;
      padding-left: 0px !important;
      padding-right: 0px !important;
      padding-bottom: 0px !important;
      flex-direction: column;
    }
    .form__rodo {
      @include mq($until: lg) {
        margin-left: 0px;
      }
    }
  }

  // &:after {
  //   content: 'polna';
  //   position: absolute;
  //   font-size: 20vw;
  //   color: rgba(255, 255, 255, 0.096);
  //   bottom: 3%;
  //   font-family: $font-family;
  //   text-transform: uppercase;
  //   right: 2%;
  // }

  .logos {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include mq($until: lg) {
      align-self: center;
    }
  }

  & > div {
    position: relative;

    & > p {
      box-shadow: 0px 0px 21px #00000029;
      position: absolute;
      height: auto;

      &:first-of-type {
        width: 50%;
        top: 0;
        right: -32%;
        z-index: 2;

        @include mq($until: xl) {
          top: -25px;
        }
      }

      &:last-of-type {
        width: 46%;
        bottom: -24%;
        left: -32%;
        z-index: 0;
      }

      @include mq($until: xl) {
        &:first-of-type {
          width: 69%;
          right: -57%;
        }
      }

      @include mq($until: lg) {
        &:first-of-type {
          width: 90%;
          right: -50%;
          top: 10%;
        }

        &:last-of-type {
          display: none;
        }
      }

      @include mq($until: lg) {
        &:first-of-type {
          display: none;
        }
      }
    }
  }

  @include mq($until: xl) {
    justify-content: flex-start;
    padding-top: 75px;
    padding-bottom: 75px;

    h2 {
      margin-left: 0;
      margin-bottom: 30px;
    }
  }

  @include mq($until: lg) {
    padding-top: 50px;
    padding-bottom: 50px;

    h2 {
      font-size: rem(30px);
    }
  }
}

.fakebox {
  width: 40px;
  height: 40px;
  border: 1px solid #c1bba9;
  display: block;
  transition: all 0.3s;

  & + span {
    width: 89%;
    margin-left: 15px;
    font-size: rem(10px);
  }

  @include mq($until: xl) {
    & + span {
      font-size: rem(10px);
      color: #ffffff;
    }
    width: 30px;
    height: 30px;
  }
}

.form__container--row {
  padding: rem(15px);
  padding-right: 15px !important;
  display: flex;
  flex-direction: column;
}

.submitSection {
  margin-top: rem(30px);
  button {
    // opacity:20%;
    background-color: #ffffff2e;
    color: #fff;
    border: none !important;
    &:hover {
      color: $color-main!important;
      background-color: #fff!important;
    }
    // @include mq($until: md) {
    //   padding-top: 50px;
    // }
  }
}
