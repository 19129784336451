.news-card{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    &__header{
        font-size: 1.2rem;
        margin-top: 15px;
    }
    &__more{
        margin-top: auto;
        padding-top: 15px;
    }
}