.first {
    position: relative;
    @include mq($from: lg) {
        min-height: 100vh;
    }
    h1 {
        text-align: center;
        color: white;
        z-index: 1;
        font-size: rem(120px);
    }

    h2 {
        color: white;
    }

    &__slogan {
        // align-items: flex-end;
        margin: 0 auto;
        padding: 80px 40px 10px;
        background: #00000073;
        margin-top: -80px;
        z-index: 0;
    }

    &__image {
        img {
            height: 100vh;
            object-fit: cover;
            object-position: bottom;
        }
    }
    &__heading {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        @include mq($until: lg) {
            .heading-text-box {
                padding: 2vw 7vw;
                background: rgb(39, 93, 144);
                background: linear-gradient(
                    45deg,
                    rgba(39, 93, 144, 1) 0%,
                    rgba(39, 93, 144, 1) 35%,
                    rgba(39, 93, 144, 0.3981967787114846) 100%
                );
                border-left: solid #fff;
                border-bottom: solid #eb6b47;
                margin-top: unset !important;
                p {
                    padding: 0 0 !important;
                }
            }
        }
        @include mq($until: xl) {
            .heading-text-box {
                margin-top: unset !important;
                p {
                    padding: 0 rem(80px);
                }
            }
        }
        @include mq($until: xxl) {
            .heading-text-box {
                p {
                    padding: unset !important;
                }
            }
        }
        @include mq($from: xxl) {
            .heading-text-box {
                // h1 {
                //     font-size: rem(90px);
                // }
                p {
                    padding: 0 rem(100px);

                    font-size: rem(18px);
                }
            }
        }
    }
    &__heading--sm {
        position: unset;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        background: rgb(39, 93, 144);
        @include mq($until: lg) {
            .heading-text-box {
                min-height: 30vh;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 2vw 7vw;
                h1 {
                    color: #fff;
                    text-align: center;
                }
                p {
                    color: #fff;
                    padding: 0 0 !important;
                }
            }
        }
    }
    @include mq($until: lg) {
        img {
            height: 80vw;
            object-fit: cover;
        }
        h1 {
            font-size: 9vw;
            margin-bottom: 2.5vw;
        }

        h2 {
            font-size: 3vw;
        }

        &__slogan {
            padding: 6vw 40px 10px;
            margin-top: -7vw;
        }
    }

    @include mq($until: sm) {
        img {
            object-fit: cover;
        }
    }

    @include mq($until: xs) {
        img {
            height: 110vw;
        }
        &__slogan {
            padding: 6vw 4vw 0;
        }
    }
}

.icons-bar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 5vw;
    &__item {
        padding-top: 3vw;
        padding-bottom: 3vw;
        max-width: 15%;
        min-width: 180px;
        display: flex;
        align-items: center;
        flex-direction: column;
        img {
            opacity: 0.4;
        }
        svg {
            width: 5vw;
            @include mq($until: lg) {
                width: 8vw;
            }
            @include mq($until: sm) {
                width: 13vw;
            }
        }
        @include mq($until: lg) {
            min-width: 40%;
        }
        p {
            text-align: center;
            padding-top: 21px;
            @include mq($until: lg) {
                font-size: rem(15px);
            }
            @include mq($until: xs) {
                font-size: rem(21px);
            }
        }
    }
}

.second {
    // padding: 8vw;
    &__content {
        display: flex;
        align-items: center;
        position: relative;
        display: flex;
        align-items: center;
        z-index: 2;
        @include mq($until: lg) {
            flex-direction: column;
            align-items: row;
        }
    }
    .row {
        @include mq($until: lg) {
            display: flex;
            flex-direction: column-reverse;
        }
    }
    &__text {
        position: relative;
        h3 {
            @include mq($from: xl) {
                padding-left: rem(250px);
            }
        }
        h5 {
            color: $color-main !important;
            line-height: 1.3;
            font-size: rem(28px);
        }

        p {
            color: $color-font-dark !important;
            line-height: 1.7;
            @include mq($from: xl) {
                padding-left: rem(250px);
            }
        }
        @include mq($until: lg) {
            padding-top: 34px;
        }
        @include mq($until: sm) {
            padding-left: 8vw;
            padding-right: 8vw;
        }
        @include mq($until: xs) {
        }
    }
    &__photo {
        width: 100%;
        img {
            max-height: 40vw;
            object-fit: cover;
            @include mq($until: sm) {
                max-height: 55vw;
            }
        }
        width: 100%;
        & > p {
            width: 100%;
        }
        @include mq($until: lg) {
            max-width: 90%;
            margin-right: auto;
        }
        @include mq($until: xs) {
            max-width: 95%;
        }
    }
    &__bg-color {
        position: absolute;
        background-color: #3f3f3f;
        height: 120%;
        width: 90%;
        right: 0;
        z-index: -1;
        overflow: hidden;
        @include mq($until: lg) {
            height: 80%;
            bottom: -10%;
        }
        @include mq($until: lg) {
            height: 90%;
        }
    }
    &__color-element {
        position: relative;
        z-index: 1;
        color: #c7d4b1;
        font-size: 400px;
        opacity: 0.2;
        right: -10%;
        bottom: -34%;
        font-family: $font-family;
        @include mq($until: lg) {
            font-size: 300px;
            bottom: -45%;
        }
        @include mq($until: lg) {
            font-size: 34vw;
            bottom: -30%;
        }
        @include mq($until: sm) {
            bottom: -30%;
        }
        @include mq($until: xs) {
            bottom: -50%;
        }
    }
}
.icons-container {
    // min-height: rem(200px);
    padding: rem(20px) 0;
    @include mq($until: sm) {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
}
.bg-image-section {
    height: 100vh;
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    &__text-box {
        padding: 2vw 7vw;
        width: 50%;
        min-height: 10vw;
        background: #e27650 0% 0% no-repeat padding-box;
        opacity: 0.9;
        @include mq($until: xl) {
            height: 40%;
        }
        @include mq($until: lg) {
            width: 100%;
            min-height: 30vw;
        }
        @include mq($from: xxl) {
            p {
                font-size: rem(30px) !important;
            }
        }
        p {
            font-family: $font-family;
            font-size: rem(20px);
            @include mq($until: lg) {
                font-size: rem(20px) !important;
            }
        }
        h2 {
            color: $color-font-light;
        }
        @include mq($from: xl) {
            p {
                font-size: rem(24px);
            }
        }
    }
}
.bg-container {
    width: 50%;
    position: absolute;
    height: 100%;
    right: 0;
    background: transparent linear-gradient(180deg, #e9e1d6 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
    z-index: -1;
}
.bg-container-left {
    @extend .bg-container;
    left: 0;
    right: unset !important;
    width: 55%;
}
.icons-section {
    padding-top: 3vw;
    padding-bottom: 7vw;
    .title-column {
        padding: 0vw 6vw 0vw 7vw;
        border-bottom: rem(3px) solid rgb(207, 207, 207);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        @include mq($until: lg) {
            display: flex;
            flex-direction: column;
            align-items: center;
            border-bottom: none;
        }
        img {
            margin: 10px;
            max-width: 50%;
        }
        p,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: $color-main !important;
            margin: 0;
            @include mq($until: lg) {
                text-align: center;
            }
        }
    }
    .icon-column {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        img {
            max-width: rem(90px);
            max-height: rem(90px);
            position: absolute;
            z-index: 9;
            // margin-top: rem(20px) * 3;
        }
    }
    .text-column {
        padding: 0 7vw 1vw 7vw;
        border-bottom: rem(3px) solid rgb(207, 207, 207);
        display: flex;
        align-items: center;
        @include mq($until: lg) {
            border-bottom: none;
        }
        @include mq($from: xxl) {
            p {
                font-size: rem(24px) !important;
            }
            h6 {
                font-size: rem(26px) !important;
            }
        }
        p {
            font-size: rem(18px);
        }
        h6 {
            font-size: rem(24px);
        }
    }
    h6,
    p {
        color: $color-font-dark !important;
    }
}

.third {
    padding-bottom: 13vw;
    &__content {
        position: relative;
        display: flex;
        align-items: center;
        z-index: 2;
        @include mq($until: lg) {
            flex-direction: column;
            align-items: row;
        }
    }
    &__text {
        padding-right: 3vw;
        max-width: 28%;
        position: relative;
        h2,
        p {
            color: #fff;
            line-height: 1.3;
        }
        p {
            line-height: 1.7;
        }
        @include mq($until: lg) {
            max-width: 80%;
            padding-top: 34px;
            padding-bottom: 34px;
            padding-right: 34px;
        }
        @include mq($until: sm) {
            padding-left: 8vw;
        }
        @include mq($until: xs) {
            max-width: 86%;
            padding-left: 0;
        }
    }
    &__photo {
        width: 100%;

        img {
            max-height: 40vw;
            object-fit: cover;
            @include mq($until: sm) {
                max-height: 55vw;
            }
        }
        @include mq($until: lg) {
            max-width: 90%;
            margin-left: auto;
        }
        @include mq($until: xs) {
            max-width: 93%;
        }
    }
    &__bg-color {
        position: absolute;
        background-color: #8b9f0b;
        height: 120%;
        width: 90%;
        left: 0;
        z-index: -1;
        overflow: hidden;
        @include mq($until: lg) {
            height: 80%;
        }
    }

    &__color-element {
        position: relative;
        z-index: 1;
        color: #7996b8;
        font-size: 400px;
        opacity: 0.2;
        left: -10%;
        bottom: -34%;
        font-family: $font-family;
        @include mq($until: lg) {
            font-size: 300px;
            bottom: -45%;
        }
        @include mq($until: lg) {
            font-size: 400px;
            bottom: -30%;
        }
        @include mq($until: sm) {
            font-size: 300px;
            bottom: -20%;
        }
    }

    @include mq($until: xs) {
        margin-top: 30px;
    }
}

.fourth {
    position: relative;
    display: flex;
    align-items: center;
    padding-bottom: 8vw;
    &--second {
        .fourth__text {
            text-align: left;
        }
        padding-top: 8vw;
        padding-bottom: 8vw;
        background-color: #fff8ee;
        @include mq($until: lg) {
            padding-top: 14vw;
            padding-bottom: 14vw;
        }
        @include mq($until: lg) {
            flex-direction: column-reverse;
            padding-top: 45px;
            padding-bottom: 45px;

            img {
                // opacity: 1!important;
            }
        }
    }
    &__text {
        position: absolute;
        z-index: 2;
        width: 32%;
        right: 0;
        text-align: center;
        z-index: 2;
        align-items: center;
        a {
            display: block;
            font-size: rem(20px);
            text-decoration: underline solid 1px #3f3f3f;
            font-family: $font-family;
        }

        h2,
        p,
        a {
            color: #3f3f3f;
            line-height: 1.3;
            margin-top: 30px;
        }

        h2 {
            text-transform: none;
        }

        b {
            font-size: rem(53px);
        }

        p {
            line-height: 1.7;
            font-size: rem(19px);
        }
        &--duzy-opis {
            width: 75%;
            padding: 3vw 19vw 3vw 5vw;
            font-size: rem(19px);
            h2,
            p {
                color: black;
            }

            h4 {
                text-transform: initial;
            }
            @include mq($until: xl) {
                padding: 3vw 12vw 3vw 5vw;
            }
            @include mq($until: lg) {
                width: 72%;
                padding: 3vw 6vw 3vw 5vw;
            }
            @include mq($until: lg) {
                position: static;
                width: 100% !important;
                padding: 8vw 50px;
            }
            @include mq($until: sm) {
                padding: 8vw 30px;
            }
            @include mq($until: xs) {
                padding: 8vw 15px;
            }
        }

        @include mq($until: lg) {
            text-shadow: 0 0 6px white;
            // width: 100%;
            padding-right: 15px;
            padding-left: 15px;
            width: 65%;
            text-align: center;
            transform: translateX(-26%);
        }
    }

    &__text-gradient {
        position: absolute;
        z-index: 1;
        width: 45%;
        height: 100%;
        right: 0;
        opacity: 0.8;
        background: rgb(9, 46, 24);
        background: linear-gradient(
            90deg,
            rgba(9, 46, 24, 0) 0%,
            rgba(6, 38, 18, 0.6068802521008403) 34%,
            rgba(0, 23, 4, 1) 100%
        );
    }

    &__photo {
        display: flex;
        align-items: center;
        width: 100%;
        position: relative;

        & > p {
            width: 100%;
        }

        img {
            height: 80vh;
            object-fit: cover;

            @include mq($until: lg) {
                opacity: 0.3;
            }
        }

        &--opis {
            img {
                width: 25%;
                object-position: right;
                box-shadow: 0px 0px 21px #00000029;
                height: 660px;
                @include mq($until: lg) {
                    width: 28%;
                    height: 580px;
                }
            }

            @include mq($until: lg) {
                img {
                    width: 100%;
                    height: 500px;
                    object-position: bottom;
                }

                p {
                    padding: 2vw 7vw;
                }
            }

            @include mq($until: sm) {
                p {
                    padding-left: 30px;
                    padding-right: 30px;
                }
            }

            @include mq($until: xs) {
                p {
                    padding-left: 15px;
                    padding-right: 15px;
                }
            }

            @include mq($until: lg) {
                position: static;
            }
        }
    }

    @include mq($until: sm) {
        &__text {
            // width:100%;
            // min-width: 300px;

            h2 {
                margin: 15px 0;
            }
        }

        img {
            height: 450px;
            object-fit: cover;
        }
    }
}
.description {
    display: flex;
    padding-top: 5vw;
    padding-bottom: 5vw;
    // background-color: #FFF8EE;
    background-color: #e0ac6a;
    @include mq($until: lg) {
        flex-direction: column;
    }

    &__photo {
        // position: -webkit-sticky;
        // position: sticky;
        // align-items: center;
        // display: flex;

        img {
            padding-right: 5vw;
            position: relative;
            position: -webkit-sticky;
            position: sticky;
            top: 5vw;
            min-width: 600px;
            max-width: 40vw;
            object-fit: cover;
            @include mq($until: xl) {
                min-width: 400px;
            }
            @include mq($until: lg) {
                width: 100%;
                min-width: unset;
                max-width: unset;
                padding-bottom: 34px;
                height: 60vw;
                object-position: center bottom;
            }
        }
    }
    &__text {
        @include mq($until: lg) {
            width: 83%;
            margin-left: auto;
            margin-right: 34px;
        }
    }
    &__more-link {
        display: inline-block;
        color: $color-extra;
        padding: 13px 21px;
        border: 1px solid $color-extra;
        // background-color: $color-extra;
        color: $color-extra;
        transition: all 0.4s ease-in-out;
        margin-top: 34px;
        &:hover {
            background-color: $color-extra;
        }
    }
}

#more {
    display: none;
}

.fifth {
    padding-bottom: 8vw;

    .icons-bar {
        max-width: 800px;
        margin: 0 auto;
    }

    h3 {
        text-transform: none;
        font-weight: 500;
        text-align: center;
        max-width: 1200px;
        margin: 0 auto 30px;
    }

    &__columns {
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
    }

    &__text {
        width: 28%;
        min-width: 250px;
        p {
            margin-bottom: 0;
            text-align: center;
            font-size: rem(19px);
        }
    }

    &__introduction {
        width: 88%;
        margin: 0 auto;
        h4 {
            text-align: center;
        }
    }

    @include mq($until: lg) {
        &__columns {
            flex-direction: column;
            & > div {
                margin-bottom: 25px;

                &:nth-child(2) {
                    margin-left: auto;
                    margin-right: auto;
                }

                &:nth-child(3) {
                    margin-left: auto;
                }
            }
        }
    }

    @include mq($until: xs) {
        &__introduction {
            width: 100%;
            h4 {
                line-height: 1.5;
            }
        }

        &__columns {
            & > div {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}
.map {
    @include mq($until: lg) {
        pointer-events: none;
    }
}
.six {
    padding-bottom: 8vw;
    &__wrapper {
        display: flex;
        justify-content: space-between;
        position: relative;
        align-items: center;
        @include mq($until: lg) {
            flex-direction: column;
        }
    }

    &__left {
        padding: 3vw 5vw 3vw 5vw;
        background-color: #4f647c;
        width: 80%;

        h3 {
            margin-top: 0;
            color: #edeff2;
            font-family: $secondary-font;
            text-transform: initial;
        }

        p {
            color: #edeff2;
        }

        @include mq($until: lg) {
            width: 100%;
            padding: 5vw 5vw 5vw 5vw;
        }
    }
    &__right {
        padding: 3vw 5vw 5vw 5vw;

        h4 {
            margin-top: 0;
            color: #4f647c;
            font-family: $secondary-font;
            text-transform: initial;
        }

        p {
            color: #4f647c;
        }

        @include mq($until: lg) {
            background-color: #edeff2;
            width: 100%;
            padding: 5vw 5vw 3vw 5vw;
        }
    }
    &__bg {
        position: absolute;
        z-index: -1;
        width: 70%;
        height: 110%;
        right: 0;
        background-color: #edeff2;
        @include mq($until: lg) {
            display: none;
        }
    }
}

.seven {
    background-color: #3f3f3f;
    padding-top: 5vw;
    padding-bottom: 5vw;
    min-height: 65vw;
    overflow: hidden;

    &--second {
        background-color: unset;

        h3 {
            font-family: $font-family;
            text-transform: none;
            max-width: 1000px;
            line-height: 1.5;
        }
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;
        position: relative;
        width: 100%;
        padding-top: 5vw;
        background-color: #8b9f0b;
    }
    &__wrpper {
        background-color: #8b9f0b;
    }
    &__head {
        padding-bottom: 5vw;
        padding-left: 16%;
    }

    &__photo {
        position: absolute;

        p img {
            height: 25vw;
            box-shadow: 0px 0px 21px #00000029;
            border: 1px solid #707070;
        }

        &--1 {
            position: absolute;
            left: -12vw;
            z-index: 1;
            top: 18vw;
        }

        &--2 {
            position: absolute;
            z-index: 2;
            left: 16vw;
        }

        &--3 {
            position: absolute;
            z-index: 3;
            top: 18vw;
            right: 16vw;
        }

        &--4 {
            position: absolute;
            right: -12vw;
            z-index: 4;
        }
    }

    @include mq($until: xl) {
        &__head {
            padding-bottom: 3vw;
        }

        &__wrpper {
            height: 44vw;
        }
    }

    @include mq($until: lg) {
        // margin-top: 75px;
    }

    @include mq($until: lg) {
        &__photo {
            p img {
                height: 27vw;
            }
        }
    }

    @include mq($until: sm) {
        &__head {
            padding-left: 9%;
        }

        &__photo {
            p img {
                height: 30vw;
            }

            &--1 {
                top: 22vw;
            }

            &--3 {
                top: 22vw;
            }

            &--2 {
                left: 9vw;
            }

            &--3 {
                right: 9vw;
            }
        }

        &__wrpper {
            height: 50vw;
        }
    }
}
.offers {
    display: flex;
    justify-content: space-between;
    padding-bottom: 5vw;
    @include mq($until: sm) {
        flex-direction: column;
    }
    &__wrapper {
        max-width: 40vw;
        display: flex;
        margin: 2vw;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        @include mq($until: lg) {
            max-width: unset;
        }
    }
    &__heading {
        padding-bottom: 21px;
        @include mq($until: lg) {
            font-size: rem(21px);
        }
    }
    &__picture {
        width: 100%;
        max-height: 400px;
        object-fit: cover;
        // @include mq($until: lg) {
        //     max-height: unset;
        //  }
    }
}

.eight {
    @include mq($until: lg) {
        margin-top: 0;
    }
    .map-wrapper {
        @include mq($until: lg) {
            overflow: auto;
            position: relative;
        }

        img {
            @include mq($until: lg) {
                width: 250%;
                vertical-align: bottom;
                max-width: unset !important;
            }
        }
    }
    h2 {
        padding-top: 5vw;
        padding-bottom: 2vw;
    }

    &__map {
        height: 40vw;
        position: relative;
        &::after {
            content: "";
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            background-color: rgba(255, 255, 255, 0.3);
            top: 0;
            z-index: 0;
            pointer-events: none;
        }
    }

    @include mq($until: sm) {
        &__map {
            height: 70vw;
        }
    }

    @include mq($until: lg) {
        // margin-top: 70px;
    }
}

.flatlist-related {
    img {
        margin-top: 50px;
        margin-bottom: 40px;
    }

    @include mq($until: lg) {
        img {
            display: none;
        }
    }
    &__widget {
        padding: 0 20vw;
        position: relative;
        @include mq($until: sm) {
            overflow: scroll;
            padding: 15vw 0;
            svg {
                // width: 450vw;
            }
        }
        @include mq($until: xs) {
            overflow: scroll;
            padding: 15vw 0;

            svg {
                width: 100vw;
            }
        }
    }
}
.w-spacer-top {
    @extend .w-spacer;
    margin: 0 10vw;
    height: 100vh;
    @include mq($from: sm) {
        margin: 0 0 !important;
    }
    @include mq($until: sm) {
        margin: 19px;
    }
    @include mq($until: lg) {
        height: 60vh;
    }
    .bg-spacer {
        @include mq($from: sm) {
            padding: 10vw 20vw;
        }
    }
}
.w-spacer {
    background-size: cover;
    position: relative;
    // padding: 6vw 0;
    display: flex;

    @include mq($until: lg) {
        display: flex;
        flex-direction: column;
    }
    &:after {
        content: "";
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        background-color: rgba(255, 255, 255, 0.9);
        top: 0;
        z-index: 0;
    }

    * {
        z-index: 5;
        position: relative;
    }

    h2 {
        text-align: center;
        margin-bottom: 40px;
    }

    &__box {
        width: 60%;
        // height: 690px;
        // max-width: 1130px;
        margin: 0 auto;
        @include mq($until: lg) {
            width: 90%;
        }
    }

    @include mq($until: lg) {
        // padding: 15vw 0;
        &__box {
            // height: 590px;
        }
    }
}
.spacer-opis {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0 rem(150px);
    p {
        color: $color-font-dark;
    }
    h5 {
        color: $color-main;
    }
    @include mq($until: xl) {
        width: 80%;
    }
    @include mq($until: lg) {
        width: 100%;
        padding: rem(50px);
    }
}
.bg-spacer {
    width: 60%;
    position: absolute;
    height: 100%;
    background-color: #f8f6f2;
    z-index: 5;
    right: 0;
    // top: rem(60px);
    @include mq($until: xl) {
        // top: rem(40px);
        width: 100%;
    }
}
.gallery-section {
    background-color: #3f3f3f;
    padding-top: 5vw;

    h2 {
        padding-left: 16%;
        color: white;
        font-size: rem(35px);
        // font-weight: 400;
        @include mq($until: sm) {
            text-align: center;
        }
    }

    @include mq($until: sm) {
        h2 {
            padding-left: 8%;
            padding-right: 15px;
        }
    }

    @include mq($until: xs) {
        h2 {
            padding-left: 15px;
        }
    }
}

.gallery {
    &-slider {
        margin: 0;
        li {
            height: 23vw;
        }

        a {
            height: 100%;
            display: block;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            transform-origin: 67% 50%;
            transform: scale(1.15);
            box-shadow: 0px 0px 21px #00000029;
        }

        .slick-track {
            margin-top: 18vw;
            margin-bottom: 11vw;
            margin-left: -12vw;

            & > div:nth-child(2n) {
                position: relative;
                bottom: 10vw;

                a {
                    transform-origin: 110% 50%;
                }
            }
        }

        @include mq($until: sm) {
            a {
                transform: scale(1.35);
            }

            .slick-track {
                margin-top: calc(20vw + 44px);
                margin-bottom: 75px;
            }
        }

        @include mq($until: xs) {
            a {
                transform: scale(1.49);
            }
        }
    }
}
.icons-head {
    text-align: center;
    padding-top: 3vw;
    padding-bottom: 3vw;
}

.find-home {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 5%;
    left: 60%;
    height: rem(80px);
    width: rem(360px);
    font-size: rem(34px);
    color: #275d90;
    font-weight: bold;
    font-family: $font-family;
    border: none;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    opacity: 0.9;
    svg {
        max-height: rem(40px);
        margin-top: rem(-5px);
    }
    @include mq($until: sm) {
        left: 20%;
    }
    @include mq($until: xl) {
        left: 35%;
    }
}
.bg-image-section__text-box--sm {
    padding: 2vw 7vw;
    width: 50%;
    height: 35%;
    background: #e27650 0% 0% no-repeat padding-box;
    opacity: 0.9;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @include mq($until: lg) {
        width: 100%;
        min-height: 20vw;
    }
    p {
        font-family: $font-family;
        font-size: rem(20px);
        @include mq($until: lg) {
            font-size: rem(20px) !important;
        }
    }
    h2 {
        color: $color-font-light;
    }
    p {
        font-size: rem(24px);
    }
}

.slick-arrow {
    display: none !important;
}

.bg-squared {
    background-color: #285d9099;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .row {
        padding: rem(30px) rem(40px) rem(30px) 0;
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
    h1 {
        margin: rem(30px) rem(30px) 0 rem(30px);
    }
    .find-home-bg {
        display: flex;
        padding: rem(10px);
        justify-content: center;
        align-items: center;
        height: rem(80px);
        width: rem(260px);
        font-size: rem(24px);
        color: #275d90;
        font-weight: bold;
        font-family: $font-family;
        border: none;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #707070;
        opacity: 0.9;
        margin: rem(10px);
        svg {
            max-height: rem(40px);
            margin-top: rem(-5px);
        }
        @include mq($until: sm) {
            left: 20%;
        }
        @include mq($until: xl) {
            left: 35%;
        }
    }
}
