.carousel-xl {
    padding-top: 10vw;
    .buttons-container {
        width: 40%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .carousel-indicators {
        position: absolute;
        right: 0;
        top: 0%;
        width: 100%;
        z-index: 2;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 0;
        margin-right: 0% !important;
        margin-bottom: 1rem;
        margin-left: 0% !important;
        list-style: none;
    }
    .carousel-indicators [data-bs-target] {
        box-sizing: content-box;
        flex: 0 1 auto;
        width: unset !important;
        height: unset !important;
        padding: unset !important;
        margin-left: unset !important;
        text-indent: unset !important;
        // cursor: pointer!important;
        background-color: #fff;
        background-clip: unset !important;
        border: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        opacity: unset !important;
        transition: opacity 0.6s ease;
        justify-content: flex-start !important;
    }
    #carouselExampleIndicators {
        .active {
            color: $color-main;
        }
    }
    a.boot-title {
        padding: rem(27px);
        font-size: rem(24px);
    }
    .carousel-item {
        min-height: 70vh;
        img {
            float: right;
            margin-top: -5%;
        }
        .carousel-caption {
            @include mq($from: xl) {
                width: 40% !important;
            }
            left: 0;
            top: 100px;
            h5,
            p {
                color: $color-font-dark;
            }
        }
    }
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
    background-image: none !important;
}
.carousel-control-prev {
    @include mq($until: sm) {
        left: -3% !important;
    }
}
.carousel-control-next {
    @include mq($until: sm) {
        right: 5% !important;
    }
}
.carousel-control-next,
.carousel-control-prev {
    top: 60% !important;
    filter: drop-shadow(0px 0px 0px black);
    opacity: unset !important;
    z-index: 9;
    @include mq($until: sm) {
        svg {
            width: 50px;
        }
    }
    @include mq($until: lg) {
        top: 0 !important;
    }
}
.carousel-control-next {
    @include mq($from: xl) {
        right: 35%;
    }
}
.carousel-control-prev {
    @include mq($from: xl) {
        left: 35%;
    }
}
#carouselExampleCaptions2 {
    height: 90vh;
    .carousel-inner {
        height: 90vh;
    }
    .carousel-caption {
        bottom: -20%;
        @include mq($until: sm) {
            bottom: -65%;
        }
        p {
            color: $color-font-dark;
        }
    }
}
.carousel-indicators {
    button.active {
        color: $color-main;
    }
}
.carousel-inner {
    .pdf-butons__separator {
        padding: 0 rem(35px);
    }
}

.lista-rzutow {
    li {
        border-bottom: 1px solid $color-main;
        display: flex;
        justify-content: space-between;
        font-weight: 600;
        padding-right:rem(18px);
        p {
            color:$color-main!important;
        }
        p:last-child {
            font-size:34px!important;
            color: $color-font-dark!important;;
        }
    }
}
