.contact-data {
  height: 100%;
  width: 100%;
  padding: rem(30px);
  padding-top: 2vw;
  padding-bottom: 2vw;
  display: flex;
  background-color: #285d90;
  @include mq($until: xs) {
    flex-direction: column;
    justify-content: center;
    padding-top: 55px;
  }
  &__content {
    height: 50px;
    width: 100%;
    border-bottom: 1px solid #fff;
    p,
    h5,
    h6 {
      color: #fff;
    }
  }
  .logos {
    display: flex;
    flex-direction: column;
    padding-right: 5vw;
    align-items: center;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    @include mq($until: sm) {
      padding-right: 8vw;
    }
    @include mq($until: xs) {
      padding-bottom: 34px;
      padding-top: 34px;
      padding-right: unset;
    }
  }

  .footer {
    a {
      color: #fff;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    &__addresss {
      width: 40vw;
    }
    &__name {
      padding-top: 28px;
      padding-bottom: 13px;
      color: #fff;
    }

    &__icon {
      padding-right: 8px;
      svg {
        fill: #fff;
        width: 21px;
      }
    }
    &__item {
      margin-bottom: 13px;
      a {
        color: #fff;
      }
      transition: all 0.3s ease-in-out;
      &:hover {
        svg {
          fill: $color-main;
        }
        a {
          color: $color-main;
        }
      }
    }
    &__slogan {
      margin-top: 8px;
      font-style: italic;
      color: #fff;
    }
  }
  &__fb {
    fill: #fff;
    width: 21px;
    transition: all 0.4s ease-in-out;
    margin: 5px;
    &:hover {
      fill: $color-main;
    }
  }
  &__insta {
    fill: #fff;
    width: 21px;
    transition: all 0.4s ease-in-out;
    margin: 5px;
    padding-right: 6px;

    &:hover {
      fill: $color-main;
    }
  }
}
