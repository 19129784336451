.header {
  display: flex;
  justify-content: space-between;
  position: relative;
  // min-height: 100px;
  position: absolute;
  align-items: flex-start;
  width: 100%;
  z-index: 10;
  display: flex;
    align-items: center;
  // border-bottom: solid 2px $color-main;
  background-color: $color-extra;
  &__logo {
    width: 15vw;
    padding: 10px;
    background-color: $color-extra;
    position: relative;
    z-index: 2;
    // margin-top: rem(13px);
    padding-bottom: rem(13px);
    // max-width: 80px;
    img {
      transition: 0.3s ease-in-out;
    }
    @include mq($until: xl) {
      max-width: 20vw;
    }
    @include mq($until: lg) {
      width: 100%;
    }
    @include mq($until: xs) {
      margin-top: rem(10px);
      max-width:50%;
      padding:10px;
    }
    transition: 0.3s ease-in-out;
  }

  &--fixed {
    position: fixed;
    width: 100%;
    transition: 0.3s ease-in-out;
    z-index: 10;

    &.sticky {
      background-color: $color-extra;
      a {
        margin-top: rem(0px);
      }
    }
  }

  &__left {
    display: flex;
    justify-items: baseline;
    @include mq($from: md) {
      border-bottom: solid rem(1px) $color-main;
      padding-bottom: 1vw;
      padding-top: 1vw;
      // padding-right: rem(250px);
      // width: 42%;
    }
  }

  &__nav {
    margin-right: 50px;
    &--fullscreen {
      width: 100%;
      height: 100vh;
      // background-color: $color-extra;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      padding-top: 100px;
      transform: translate(0, -100%);
      transition: transform 0.3s ease-in-out;
    }
    &--visible {
      transform: translate(0, 0);
    }
  }

  &__menuList {
    display: flex;
    // justify-content: flex-end;
    align-items: center;
    margin: 0;
    &--fullscreen {
      flex-direction: column;
    }
  }

  &__menuItem {
    // margin-left: 10px;
    padding: 0;
    &--fullscreen {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
    }
    &::before {
      display: none;
    }
  }

  &__menuLink {
    color: #fff;
    font-weight: $fw-regular;
    margin-right: rem(75px);
    @include mq($until: lg) {
      margin-left: 0;
    }
    @include mq($until: xl) {
      margin-right: rem(45px);
      font-size: rem(16px);
    }
    font-size: rem(20px);
    @include mq($until: sm) {
      margin-right: 0px;
    }
    &--fullscreen {
      color: #ffffff;
      // font-size: rem(28px);
    }
    &:active,
    &--active,
    &--open,
    &:hover {
      color: $color-main;
      // font-weight: $fw-bold;
    }
  }

  .svg-icon {
    margin: 0 0 0 13px;
    width: 30px;
    fill: white;
    &:hover {
      fill: #e0ac6a;
    }
  }

  @include mq($until: lg) {
    &__nav {
      margin-right: 0;
    }

    &__left {
      flex-direction: row-reverse;
      align-items: center;
    }

    &__hamburger {
      margin-left: 18px;
      margin-right: 5vw;
    }
  }

  @include mq($until: sm) {
    min-height: unset;
    // height: 80px;
  }
  &__phone {
    margin: 0 0 0 18px;
    display: flex;
    align-items: center;
    transition: all 0.4s ease-in-out;
    p {
      color: #fff;
      margin-left: 8px;
      margin-bottom: 0;
      line-height: 0;
    }
    svg {
      width: 30px;
      fill: #fff;
    }
    &:hover > * {
      color: $color-main;
      fill: $color-main;
    }
  }
  &__head-number {
    @include mq($until: lg) {
      display: none;
    }

    transition: all 0.4s ease-in-out;
  }
  svg {
    transition: all 0.4s ease-in-out;
    &:hover {
      fill: $color-main;
    }
  }
  &__buton-sub {
    display: inline-block;
    padding: 13px 21px;
    color: $color-main;
    background-color: $color-extra;
    transition: all 0.4s ease-in-out;
    &:hover {
      color: $color-extra;
      background-color: $color-main;
    }
  }
}

.header--fixed.sticky {
  img {
    // max-width: rem(180px)!important;
    transition: 0.3s ease-in-out;
  }
}
.header__container {
  height: 100%;
  background: #275d90;
  padding: 1vw 3vw;
  top: 0;
  @include mq($until: lg) {
    background: none;
  }
}
