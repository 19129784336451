.flatlist-related {
  @include mq($until: lg) {
    margin-top: 0;
  }
  p {
    color: $color-font-dark;
    font-size: rem(20px);
  }
  ul {
    list-style: none;
    margin: 0px !important;
    li {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: rem(70px);
    }
    @include mq($until: lg) {
      margin-top: rem(30px) !important;
    }
  }
  li svg {
    max-height: rem(80px);
    margin-top: rem(-20px);
    tspan:first-of-type {
      font-size: rem(32px);
      @include mq($until: lg) {
        font-size: rem(40px);
      }
    }

    tspan {
      font-size: rem(20px);
      @include mq($until: lg) {
        font-size: rem(34px);
      }
    }
  }
  li p {
    font-family: $secondary-font;
    font-weight: $fw-bold;
    font-size: rem(22px);
    max-width: 80%;
    @include mq($until: lg) {
      max-width: 60%;
    }
  }
}

.flat-list-page {
  &__front-image {
    img {
      display: block;
      width: 100%;
    }
  }
  &__main-text {
    margin: 100px 0 200px 0;
    @include mq($until: lg) {
      margin: 50px 0 100px 0;
    }
    p {
      font-size: rem(20px);
    }
  }
}

.flat-list {
  .pdf-butons {
  }
  @include mq($until: sm) {
    margin-top: 40px;
  }
  font-family: $font-family;
  &__wrapper {
    // margin-top: 140px;
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    @include mq($until: lg) {
      margin-bottom: 80px;
    }
  }
  &__head {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    justify-content: stretch;
    align-items: center;
    padding: 15px 20px;
    margin-bottom: 5px;
    background-color: white;
    // @include mq($until: xl) {
    //   grid-template-columns: 1fr 2fr 1fr 1fr 2fr 2fr 2fr 2fr;
    // }
    // @include mq($until: lg) {
    //   grid-template-columns: 1fr 1fr 1fr 1fr 2fr 1fr 2fr 2fr;
    //   grid-gap: 5px;
    // }
    @include mq($until: lg) {
      display: none;
    }
    position: relative;
  }
  &__head-item {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: $fw-medium;
    font-size: rem(34px);
    font-weight: 900;
    color: #3f3f3f;
    font-family: $font-family;
    &--action {
      cursor: pointer;
      transition: color 0.3s;
      &:hover {
        color: $color-extra;
      }
    }
    &.active {
      color: $color-extra;
    }
    i {
      margin-right: 5px;
      padding-bottom: 9px;
    }
    &[data-order="asc"] {
      i {
        transform: scale(1);
      }
    }
    &[data-order="desc"] {
      i {
        transform: scale(-1);
      }
    }
    @include mq($until: xxl) {
      font-size: rem(28px);
    }

    @include mq($until: xl) {
      font-size: rem(24px);
    }

    &--left {
      justify-content: flex-start;
    }
    &--right {
      justify-content: flex-end;
    }
  }
  &__item {
    p,
    span {
      font-size: rem(21px);
    }
    display: grid;
    padding: 0 20px;
    // grid-template-columns: repeat(8, 1fr);
    grid-template-columns: repeat(6, 1fr);
    // display: flex;
    // justify-content: space-around;
    justify-content: stretch;
    align-items: center;
    transition: 0.3s;
    position: relative;
    margin-bottom: 5px;
    background-color: #f8f6f2;
    transition: all 0.3s;
    // width: 100%;
    &:hover {
      // color: #fff;
    }
    a:visited {
      background-color: $color-main;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &:nth-child(2n) {
      background-color: #d9d7d4;
      a:visited {
        background-color: $color-main;
      }
    }
    &:hover span {
      color: $color-extra;
    }

    &.dostępny:hover {
      background-color: #1ebd38;
      span {
        color: white;
      }
    }
    &.sprzedany:hover {
      background-color: #f74343;
      span {
        color: white;
      }
    }
    &.rezerwacja:hover {
      background-color: #dee040;
      color: white;
      span {
        color: white;
      }
    }
    @include mq($until: xl) {
      grid-template-columns: repeat(6, 1fr);
    }
    @include mq($until: lg) {
      grid-template-columns: repeat(6, 1fr);
    }

    @include mq($until: lg) {
      display: flex;
      // flex-direction: row;
      // flex-wrap: wrap;
      // justify-content: flex-start;
      // align-items: flex-start;
      flex-direction: column;
      margin-bottom: 0px;
      grid-gap: 0;
      padding: 20px;
    }
    @include mq($until: sm) {
      flex-direction: column;
      align-items: center;
    }
  }
  &__status {
    height: 12px;
    width: 12px;
    border-radius: 100%;
    margin-right: 5px;
  }
  &__info {
    font-family: $secondary-font;
    display: flex;
    padding: 20px 0;
    justify-content: center;
    align-items: center;
    font-size: rem(24px);
    // color: white;
    @include mq($until: xxl) {
      font-size: rem(20px);
    }
    &:hover {
      color: white;
    }
    @include mq($until: lg) {
      font-size: rem(21px);
    }
    @include mq($until: lg) {
      justify-content: flex-start;
      font-weight: $fw-bold;
      font-size: rem(21px);
      padding: 0;
      flex-basis: 100%;
      margin-bottom: 5px;
      &:first-child {
        // margin-bottom: 13px;
      }
      &:last-child {
        margin-left: auto;
        // margin-bottom: 5px;
        @include mq($until: sm) {
          margin-left: unset;
        }
      }
    }
    // @include mq($until: xs) {
    //   justify-content: space-between;
    //   margin-top: 5px;
    // }
    &--left {
      justify-content: flex-start;
    }
    &--right {
      justify-content: flex-end;
    }
    &--button {
      @include mq($until: lg) {
        flex-basis: 48%;
        .flat-list__item-label {
          display: none;
          color: $color-font-dark;
        }
        .flat-list__item-value {
          width: 100%;
          button,
          a,
          span {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin: 0;
          }
        }
      }
    }
  }
  &__item-value {
    position: relative;
  }
  &__item-label {
    display: none;
    color: $color-font-dark;
    @include mq($until: lg) {
      display: block;
      margin-right: 10px;
      font-weight: $fw-regular;
      font-size: rem(20px);
      display: inline;
    }
  }
  &__item-value {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: rem(28px);
    @include mq($until: lg) {
      font-size: rem(24px);
    }
    @include mq($until: lg) {
      font-size: rem(20px);
      font-weight: $fw-bold;
      display: inline;
    }
    span:first-letter {
      text-transform: uppercase;
    }
    span,
    a {
      font-family: $secondary-font;
      font-weight: 800;
      margin-left: 5px;
      color: #3f3f3f;
      transition: all 0.3s;
      font-size: rem(24px);
      @include mq($until: xxl) {
        font-size: rem(20px);
      }
      @include mq($until: lg) {
        font-size: rem(18px);
      }
    }
    svg {
      height: 20px;
      fill: $color-main;
      transition: 0.2s;
      margin-right: 5px;
      @include mq($until: xl) {
        height: 21px;
      }
    }
    a,
    button {
      border: 1px solid #000;
      background-color: #fff;
      padding: 5px 10px;
      transition: 0.2s;

      &:hover {
        border: 1px solid $color-main;
        background-color: $color-main;
        color: $color-font-light;
        svg {
          fill: $color-font-light;
          @include mq($until: lg) {
            // width: 10px
          }
        }
      }
      @include mq($until: lg) {
        padding: 5px 8px;
        font-size: rem(21px);
        font-weight: $fw-semi-bold;
      }
    }
  }

  &__no-results {
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;
    font-size: rem(26px);
    color: $border-color;
  }
}
.pdf-butons {
  display: flex;
  align-items: center;
  justify-content: center;
  @include mq($until: lg) {
    svg {
      width: rem(40px);
    }
    a {
      margin-bottom: 5px;
    }
  }

  &__separator {
    padding: 0 15px;
    opacity: 0.8;
    color: black !important;
    @include mq($until: lg) {
      padding: 0 13px;
    }
  }
}
